<script lang="ts" setup>
import type { PageEntityResponse, ComponentBlockFooter } from "~~/types/graphql";

const props = withDefaults(
  defineProps<{
    headline: ComponentBlockFooter["Titel"];
    link?: ComponentBlockFooter["link"];
  }>(),
  {}
);
</script>
<template>
  <footer class="footer bg-integer-dark-green">
    <div class="pt-20 md:pt-25 container outer-space">
      <nuxt-link :to="link ?? ''">
        <h2 class="text-integer-light-green text-h2 md:max-2xl:text-h1 2xl:text-h1max font-pixel sm:max-w-4/5 hyphens-auto">
          {{ props.headline }}
        </h2>
      </nuxt-link>
    </div>
  </footer>
</template>
